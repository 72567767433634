import React from "react";
import { checkIpBlacklist } from "../forms/models/blacklist/methods/checkIpBlacklist";

export const BLACK_LIST_STATUSES = {
  LOADING: "loading",
  ERROR: "error",
  BLOCKED: "blocked",
  NOT_BLOCKED: "not_blocked",
};

const defaultState = {
  shouldShownBlackListModal: false,
  blackListStatus: BLACK_LIST_STATUSES.LOADING,
};

export const BlacklistContext = React.createContext(defaultState);

const retryCheckBlacklist = async (retries, delay) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await checkIpBlacklist();
    } catch (error) {
      if (i === retries - 1) {
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }
};

export const BlacklistProvider = ({ children }) => {
  const [blackListStatus, setBlackListStatus] = React.useState(
    BLACK_LIST_STATUSES.LOADING
  );
  const shouldShownBlackListModal =
    blackListStatus === BLACK_LIST_STATUSES.LOADING ||
    blackListStatus === BLACK_LIST_STATUSES.ERROR ||
    blackListStatus === BLACK_LIST_STATUSES.BLOCKED;

  React.useEffect(() => {
    const fetchData = async () => {
      setBlackListStatus(BLACK_LIST_STATUSES.LOADING);

      try {
        const ipBlacklistData = await retryCheckBlacklist(3, 1000);

        if (ipBlacklistData.isBlacklisted) {
          setBlackListStatus(BLACK_LIST_STATUSES.BLOCKED);
        } else {
          setBlackListStatus(BLACK_LIST_STATUSES.NOT_BLOCKED);
        }
      } catch {
        setBlackListStatus(BLACK_LIST_STATUSES.ERROR);
      }
    };

    fetchData();
  }, []);

  return (
    <BlacklistContext.Provider
      value={{
        shouldShownBlackListModal,
        blackListStatus,
      }}
    >
      {children}
    </BlacklistContext.Provider>
  );
};
